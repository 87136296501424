import React from 'react';

function Block2() {
  const benefits = [
    {
      text: 'Короткое северное лето обеспечивает невозможное в других регионах разнообразие одновременно цветущих медоносов',
      image: '/images/bullits.png',
    },
    {
      text: 'Климатические условия Заполярья исключают возможность появления многих «пчелиных» болезней',
      image: '/images/bullits.png',
    },
    {
      text: 'Чистая природа, незнакомая с химическими удобрениями и инсектицидами',
      image: '/images/bullits.png',
    },
  ];

  return (
    <div className="relative h-full w-full bg-black px-0 lg:px-16 py-0 lg:py-32 text-white flex flex-col justify-around">
      {/* Заголовок */}
      <div className="flex justify-center relative z-10 px-6 py-8 lg:py-0 lg:px-0">
        <h2 className="text-3xl lg:text-5xl text-center lg:font-semibold font-bold">О заполярье</h2>
      </div>

      {/* Описание с иконками, центрированное по вертикали */}
      <div className="flex items-center relative z-10 px-6 py-4 lg:py-0 lg:px-0 justify-center lg:justify-start">
        <div className="flex flex-col gap-8 lg:gap-12 text-xl lg:text-2xl w-full lg:w-1/2 pt-0 lg:pt-16">
          <div className="space-y-4 lg:space-y-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="flex flex-col text-center lg:text-start lg:flex-row lg:items-start items-center lg:justify-start justify-center gap-4">
                <img src={benefit.image} alt="bullet" className="w-9 lg:w-8 h-9 lg:h-8" />
                <p>{benefit.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Фоновое изображение */}
      <img
        src="/images/map.png"
        alt="Background"
        className="relative lg:absolute bottom-0 lg:-bottom-24 right-0 h-full object-cover"
      />
    </div>
  );
}

export default Block2;
