// src/components/ProductsPanel.js
import React, { useEffect, useState } from 'react';
import { getProducts, addProduct, updateProduct, deleteProduct } from '../../api';

function Products() {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', unit: '' });
  const [editingProductId, setEditingProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        setProducts(Array.isArray(data) ? data : []); // Убедитесь, что data — массив
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setProducts([]); // Устанавливаем пустой массив при ошибке
      }
    };

    fetchProducts();
  }, []);

  const toggleProductActive = async (id, is_active) => {
    try {
      const product = products.find((p) => p.id === id);

      if (!product) {
        console.error('Продукт не найден');
        return;
      }

      const updatedProduct = await updateProduct(id, {
        ...product,
        is_active: !is_active,
      });

      setProducts(
        products.map((p) => (p.id === id ? { ...p, is_active: updatedProduct.is_active } : p)),
      );
    } catch (error) {
      console.error('Ошибка при обновлении статуса товара:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddProduct = async () => {
    try {
      const addedProduct = await addProduct({ ...newProduct, is_active: true }); // Добавляем `isActive: true` для новых продуктов
      setProducts([...products, addedProduct]);
      setNewProduct({ name: '', price: '', unit: '' });
    } catch (error) {
      console.error('Ошибка при добавлении товара:', error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProduct(id);
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении товара:', error);
    }
  };

  const handleEditProduct = (product) => {
    setEditingProductId(product.id);
    setEditedProduct(product);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      await updateProduct(editingProductId, editedProduct);
      setProducts(
        products.map((product) =>
          product.id === editingProductId ? { ...product, ...editedProduct } : product,
        ),
      );
      setEditingProductId(null); // Закрываем режим редактирования
    } catch (error) {
      console.error('Ошибка при сохранении изменений товара:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingProductId(null);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Список товаров</h2>
      {/* Форма добавления */}
      <div className="bg-white shadow rounded-lg p-4 space-y-4">
        <h3 className="font-bold text-lg">Добавить товар</h3>
        <input
          type="text"
          name="name"
          placeholder="Название товара"
          value={newProduct.name}
          onChange={handleInputChange}
          className="p-2 border w-full"
        />
        <input
          type="text"
          name="price"
          placeholder="Цена"
          value={newProduct.price}
          onChange={handleInputChange}
          className="p-2 border w-full"
        />
        <input
          type="text"
          name="unit"
          placeholder="Единица измерения (шт/кг)"
          value={newProduct.unit}
          onChange={handleInputChange}
          className="p-2 border w-full"
        />
        <button
          onClick={handleAddProduct}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200">
          Добавить товар
        </button>
      </div>
      {/* Список продуктов */}
      <ul className="space-y-4">
        {products.map((product) => (
          <li
            key={product.id}
            className="p-4 border rounded-lg flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
            {editingProductId === product.id ? (
              // Режим редактирования
              <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 w-full">
                <input
                  type="text"
                  name="name"
                  value={editedProduct.name}
                  onChange={handleEditInputChange}
                  className="p-2 border rounded w-full sm:w-auto"
                />
                <input
                  type="text"
                  name="price"
                  value={editedProduct.price}
                  onChange={handleEditInputChange}
                  className="p-2 border rounded w-full sm:w-auto"
                />
                <input
                  type="text"
                  name="unit"
                  value={editedProduct.unit}
                  onChange={handleEditInputChange}
                  className="p-2 border rounded w-full sm:w-auto"
                />
                <div className="flex space-x-2">
                  <button
                    onClick={handleSaveEdit}
                    className="bg-green-500 text-white px-4 py-2 rounded">
                    Сохранить
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="bg-gray-400 text-white px-4 py-2 rounded">
                    Отмена
                  </button>
                </div>
              </div>
            ) : (
              // Режим отображения
              <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center w-full">
                <div className="flex flex-col space-y-1">
                  <p className="font-bold">{product.name}</p>
                  <p className="text-sm text-gray-600">
                    Цена: {product.price} р. / {product.unit}
                  </p>
                </div>
                <div className="flex flex-wrap items-center space-x-2 space-y-2 sm:space-y-0">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={product.is_active}
                      onChange={() => toggleProductActive(product.id, product.is_active)}
                      className="h-4 w-4 text-blue-500"
                    />
                  </label>
                  <button
                    onClick={() => handleEditProduct(product)}
                    className="bg-yellow-500 text-white px-4 py-2 rounded">
                    Редактировать
                  </button>
                  <button
                    onClick={() => handleDeleteProduct(product.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded">
                    Удалить
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Products;
