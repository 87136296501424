import React, { useEffect, useState } from 'react';
import { getCharacteristics } from '../api';

function Block4() {
  const [characteristics, setCharacteristics] = useState([]);

  useEffect(() => {
    const fetchCharacteristics = async () => {
      try {
        const data = await getCharacteristics();
        if (Array.isArray(data)) {
          setCharacteristics(data);
        } else {
          console.error('Ошибка: данные не являются массивом', data);
          setCharacteristics([]);
        }
      } catch (error) {
        console.error('Ошибка при загрузке характеристик:', error);
        setCharacteristics([]);
      }
    };

    fetchCharacteristics();
  }, []);

  return (
    <div className="relative h-full w-full bg-black px-6 lg:px-16 py-8 lg:pt-32 text-white flex flex-col justify-around">
      <img
        src="/images/block3.jpg"
        alt="Background"
        className="absolute bottom-0 left-0 h-full object-cover"
      />

      <div className="flex flex-col gap-2 justify-around relative z-10">
        <h2 className="text-3xl lg:text-5xl text-center lg:font-semibold font-bold">
          Уникальные характеристики
        </h2>
        <div className="flex justify-center pt-2 relative z-10 text-center">
          <h3 className="text-xl lg:text-2xl">подтвержденные лабораторными испытаниями</h3>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-2 flex items-center relative z-10 py-8">
        <div></div>
        <div className="flex flex-col gap-6 lg:gap-8 text-2xl w-full text-center lg:text-start lg:w-auto">
          <div className="space-y-6 lg:space-y-6">
            {characteristics.map((char) => (
              <div key={char.id} className="flex lg:flex-row flex-col items-center gap-3 lg:gap-4">
                <img src="/images/bullits.png" className="w-9 h-9 lg:w-8 lg:h-8" />
                <div className="flex flex-col lg:flex-row relative items-center gap-0 lg:gap-2 w-full lg:w-auto">
                  <div className="bg-[#0f0d0d] px-4 lg:px-2 py-3 lg:py-1 rounded mb-1 relative cursor-pointer group w-full lg:w-auto">
                    <span>{char.name}:</span>
                    <div className="z-50 absolute shadow-xl top-full mt-0 lg:mt-2 left-1/2 transform -translate-x-1/2 w-full p-2 lg:p-4 bg-white text-black text-sm lg:text-xl rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                      {char.tooltip}
                    </div>
                  </div>
                  <div className="lg:text-2xl text-2xl font-semibold">{char.value}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <a
        href="/protocol.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="flex z-[1] justify-end items-center">
        <img src="/images/frame_PDF.png" className="lg:w-2/5 w-full" />
      </a>
    </div>
  );
}

export default Block4;
