import React, { useEffect, useState } from 'react';
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';

import Header from './components/Header';
import Block1 from './components/Block1';
import Block2 from './components/Block2';
import Block3 from './components/Block3';
import Block4 from './components/Block4';
import Block5 from './components/Block5';
import Block6 from './components/Block6';
import Footer from './components/Footer';
import OrderModal from './components/OrderModal';

function App() {
  const [activeAnchor, setActiveAnchor] = useState('block1');
  const [cart, setCart] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const isIPad = /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

    const handleResize = () => {
      const isMobileWidth = window.innerWidth <= 1024;
      // Для iPad проверяем ширину экрана отдельно
      setIsMobile(isMobileWidth || isIPad);
    };

    window.addEventListener('resize', handleResize);

    if (!isMobile) {
      // Инициализация fullpage.js только для десктопов
      const fullpageInstance = new fullpage('#fullpage', {
        autoScrolling: true,
        navigation: false,
        showActiveTooltip: true,
        scrollingSpeed: 700,
        fitToSection: true,
        fitToSectionDelay: 500,
        responsive: 900,
        scrollOverflow: false,
        continuousVertical: false,
        scrollHorizontally: false,
        bigSectionsDestination: 'top',
        anchors: ['block1', 'block2', 'block3', 'block4', 'block5', 'block6', 'footer'],
        navigationTooltips: [
          'Главная',
          'О заполярье',
          'Урожай',
          'Характеристики',
          'Товары',
          'Статьи',
          'Контакты',
        ],
        onLeave: function (origin, destination) {
          setActiveAnchor(destination.anchor);
        },
      });

      return () => {
        if (fullpageInstance) {
          fullpageInstance.destroy('all'); // Удаляем fullpage.js при изменении условия
        }
      };
    }

    // Если мобильное устройство или iPad — очищаем настройки
    if (window.fullpage_api) {
      window.fullpage_api.destroy('all');
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.width = '100%';
  };

  const unlockScroll = () => {
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  const openModal = (product) => {
    setCart(
      products
        .filter((prod) => prod.is_active) // Добавляем только активные продукты
        .map((prod, index) => ({
          ...prod,
          quantity: prod.id === product.id && index === 0 ? 0.5 : prod.id === product.id ? 1 : 0,
        })),
    );

    lockScroll();
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Отключение прокрутки страницы
    if (!isMobile && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = ''; // Включение прокрутки страницы
    if (!isMobile && window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(true);
    }
    unlockScroll();
    if (successMessage) {
      console.log('successMessage', successMessage);
      setTimeout(() => setSuccessMessage(''), 5000); // Скрываем сообщение через 5 секунд
    }
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateQuantity = (productId, delta) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: Math.max(0, item.quantity + delta) } : item,
      ),
    );
  };

  return (
    <div>
      {successMessage && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-6 py-2 rounded-lg shadow-md">
          {successMessage}
        </div>
      )}
      <Header activeAnchor={activeAnchor} />
      <div id="fullpage" className={isMobile ? 'auto-scroll' : ''}>
        <div className="section" data-anchor="block1">
          <Block1 />
        </div>
        <div className="section " data-anchor="block2">
          <Block2 />
        </div>
        <div className="section " data-anchor="block3">
          <Block3 openModal={openModal} />
        </div>
        <div className="section " data-anchor="block4">
          <Block4 />
        </div>
        <div className="section " data-anchor="block5">
          <Block5 openModal={openModal} products={products} setProducts={setProducts} />
        </div>
        <div className="section" data-anchor="block6">
          <Block6 />
        </div>
        <div className="section " data-anchor="footer">
          <Footer />
        </div>
      </div>

      {isModalOpen && (
        <OrderModal
          cart={cart}
          onClose={closeModal}
          updateQuantity={updateQuantity}
          onClearCart={clearCart}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
}

export default App;
