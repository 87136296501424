import React, { useEffect, useState } from 'react';
import {
  getCharacteristics,
  updateCharacteristic,
  addCharacteristic,
  deleteCharacteristic,
} from '../../api';

function Characteristics() {
  const [characteristics, setCharacteristics] = useState([]);
  const [newCharacteristic, setNewCharacteristic] = useState({ name: '', value: '', tooltip: '' });
  const [editingCharId, setEditingCharId] = useState(null);
  const [editedCharacteristic, setEditedCharacteristic] = useState({});

  useEffect(() => {
    const fetchCharacteristics = async () => {
      try {
        const data = await getCharacteristics();
        setCharacteristics(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Ошибка при загрузке характеристик:', error);
        setCharacteristics([]);
      }
    };

    fetchCharacteristics();
  }, []);

  const handleAddCharacteristic = async () => {
    if (!newCharacteristic.name || !newCharacteristic.value) {
      alert('Заполните все поля для новой характеристики.');
      return;
    }
    try {
      const addedCharacteristic = await addCharacteristic(newCharacteristic);
      setCharacteristics([...characteristics, addedCharacteristic]);
      setNewCharacteristic({ name: '', value: '', tooltip: '' });
    } catch (error) {
      console.error('Ошибка при добавлении характеристики:', error);
    }
  };

  const handleDeleteCharacteristic = async (id) => {
    try {
      await deleteCharacteristic(id);
      setCharacteristics(characteristics.filter((char) => char.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении характеристики:', error);
    }
  };

  const handleEditCharacteristic = (char) => {
    setEditingCharId(char.id);
    setEditedCharacteristic(char);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCharacteristic((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      await updateCharacteristic(editingCharId, editedCharacteristic);
      setCharacteristics(
        characteristics.map((char) =>
          char.id === editingCharId ? { ...char, ...editedCharacteristic } : char,
        ),
      );
      setEditingCharId(null);
    } catch (error) {
      console.error('Ошибка при сохранении изменений характеристики:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCharId(null);
  };

  return (
    <div className="max-w-5xl mx-auto space-y-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Управление характеристиками</h2>

      {/* Форма для добавления новой характеристики */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-bold mb-4 text-gray-700">Добавить новую характеристику</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder="Название"
            value={newCharacteristic.name}
            onChange={(e) => setNewCharacteristic({ ...newCharacteristic, name: e.target.value })}
            className="p-2 border border-gray-300 rounded shadow-sm"
          />
          <input
            type="text"
            placeholder="Значение"
            value={newCharacteristic.value}
            onChange={(e) => setNewCharacteristic({ ...newCharacteristic, value: e.target.value })}
            className="p-2 border border-gray-300 rounded shadow-sm"
          />
          <input
            type="text"
            placeholder="Текст для тултипа"
            value={newCharacteristic.tooltip}
            onChange={(e) =>
              setNewCharacteristic({ ...newCharacteristic, tooltip: e.target.value })
            }
            className="p-2 border border-gray-300 rounded shadow-sm"
          />
        </div>
        <button
          onClick={handleAddCharacteristic}
          className="mt-4 bg-green-600 text-white px-4 py-2 rounded shadow hover:bg-green-700 transition duration-200">
          Добавить
        </button>
      </div>

      {/* Список характеристик */}
      <ul className="space-y-4">
        {characteristics.map((char) => (
          <li
            key={char.id}
            className="bg-white shadow-md rounded-lg p-6 flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0">
            {editingCharId === char.id ? (
              // Режим редактирования
              <div className="w-full space-y-2 md:space-y-0 md:flex md:items-center md:space-x-4">
                <input
                  type="text"
                  name="name"
                  value={editedCharacteristic.name}
                  onChange={handleEditInputChange}
                  className="w-full md:w-1/4 p-2 border border-gray-300 rounded shadow-sm"
                />
                <input
                  type="text"
                  name="value"
                  value={editedCharacteristic.value}
                  onChange={handleEditInputChange}
                  className="w-full md:w-1/4 p-2 border border-gray-300 rounded shadow-sm"
                />
                <input
                  type="text"
                  name="tooltip"
                  value={editedCharacteristic.tooltip}
                  onChange={handleEditInputChange}
                  className="w-full md:w-1/4 p-2 border border-gray-300 rounded shadow-sm"
                />
                <div className="flex space-x-4 mt-4 md:mt-0">
                  <button
                    onClick={handleSaveEdit}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200">
                    Сохранить
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200">
                    Отмена
                  </button>
                </div>
              </div>
            ) : (
              // Режим отображения
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
                <div className="space-y-1">
                  <p className="font-bold text-gray-700">{char.name}</p>
                  <p className="text-gray-600">Значение: {char.value}</p>
                  <p className="text-sm text-gray-500">{char.tooltip}</p>
                </div>
                <div className="flex space-x-4 mt-4 md:mt-0">
                  <button
                    onClick={() => handleEditCharacteristic(char)}
                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-200">
                    Редактировать
                  </button>
                  <button
                    onClick={() => handleDeleteCharacteristic(char.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200">
                    Удалить
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Characteristics;
