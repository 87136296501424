import React from 'react';

function Block6() {
  return (
    <div
      className="relative h-full w-full py-8 lg:py-24 bg-cover text-white flex flex-col justify-center items-center"
      style={{ backgroundImage: 'url("/images/block4.jpg")' }}>
      <div className="w-full bg-black px-4 py-6 lg:px-16 shadow-lg">
        {/* Первый блок с раскрывающимся описанием */}
        <details className="mb-4 text-xl lg:text-2xl text-black">
          <summary className="bg-white px-4 py-3 lg:px-8 lg:py-4 rounded-lg cursor-pointer">
            В процессе написания ...
          </summary>
          <p className="bg-white px-4 py-2 lg:px-8 lg:py-4 rounded-lg mt-2"></p>
        </details>

        {/* Второй блок с раскрывающимся описанием */}
        <details className="mb-4 text-xl lg:text-2xl text-black">
          <summary className="bg-white px-4 py-3 lg:px-8 lg:py-4 rounded-lg cursor-pointer">
            В процессе написания ...
          </summary>
          <p className="bg-white px-4 py-2 lg:px-8 lg:py-4 rounded-lg mt-2"></p>
        </details>
      </div>
    </div>
  );
}

export default Block6;
