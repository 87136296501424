// src/components/QuantityPanel.js
import React, { useEffect, useState } from 'react';
import { getQuantity, updateQuantity } from '../../api';

function Quantity() {
  const [quantity, setQuantity] = useState({ total_collected: 0, remaining: 0 });

  useEffect(() => {
    const fetchQuantity = async () => {
      try {
        const data = await getQuantity();
        console.log(data);
        setQuantity(data); // Устанавливаем полученный объект
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setQuantity({ total_collected: 0, remaining: 0 }); // Устанавливаем пустые значения при ошибке
      }
    };

    fetchQuantity();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuantity((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleUpdateQuantity = async () => {
    try {
      await updateQuantity(quantity);
      alert('Количество обновлено успешно');
    } catch (error) {
      console.error('Ошибка при обновлении количества:', error);
    }
  };

  return (
    <div className="max-w-xl mx-auto bg-white shadow rounded-lg p-6 space-y-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Управление количеством</h2>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Всего собрали:</label>
          <input
            type="text"
            name="total_collected"
            value={quantity.total_collected}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded shadow-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Осталось от урожая:</label>
          <input
            type="text"
            name="remaining"
            value={quantity.remaining}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded shadow-sm"
          />
        </div>
      </div>
      <button
        onClick={handleUpdateQuantity}
        className="w-full bg-green-600 text-white px-4 py-2 rounded shadow hover:bg-green-700 transition duration-200">
        Сохранить
      </button>
    </div>
  );
}

export default Quantity;
