import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { addOrder } from '../api';

function OrderModal({ cart, onClose, updateQuantity, onClearCart }) {
  const [customerData, setCustomerData] = useState({ name: '', phone: '', city: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setHeight();
    window.addEventListener('resize', setHeight);

    return () => window.removeEventListener('resize', setHeight);
  }, []);

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitOrder = async () => {
    if (!customerData.name || !customerData.phone) {
      setError('Пожалуйста, заполните обязательные поля: имя и номер телефона.');
      setTimeout(() => setError(''), 3000);
      return;
    }

    const orderData = {
      ...customerData,
      items: cart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
        price: item.price,
      })),
      total_price: calculateTotalPrice(),
    };

    try {
      await addOrder(orderData);
      setSuccessMessage(`Заказ на ${calculateTotalPrice().toLocaleString()} р. успешно оформлен!`);
      setTimeout(() => {
        onClearCart();
        onClose(); // Закрываем окно через 2 секунды
      }, 2000);
    } catch (error) {
      console.error('Ошибка при оформлении заказа:', error);
      setError('Ошибка при оформлении заказа');
      setTimeout(() => setError(''), 3000);
    }
  };

  return (
    <div
      id="modal-background"
      onClick={(e) => e.target.id === 'modal-background' && onClose()}
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 overflow-y-auto">
      {successMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white py-6 px-8 rounded-lg text-center">
            <h2 className="text-lg font-bold text-green-600 mb-4">{successMessage}</h2>
            <button
              onClick={() => {
                setSuccessMessage('');
                onClose();
              }}
              className="bg-green-500 text-white px-6 py-2 rounded-lg">
              Закрыть
            </button>
          </div>
        </div>
      )}

      <div
        id="modal-content"
        className="bg-[#3b3b3b] py-8 px-4 md:px-12 w-full md:w-1/2 max-h-screen overflow-y-auto text-center text-white relative">
        {error && (
          <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-6 py-2 rounded-lg shadow-md">
            {error}
          </div>
        )}
        <button onClick={onClose} className="absolute top-6 right-4 text-5xl">
          ×
        </button>

        <div className="mb-4">
          <h2 className="text-xl mb-4 text-[#6D6A67] uppercase">Оформление заказа</h2>
          <h3 className="text-lg">
            Бесплатная доставка по г.Санкт-Петербург. В другие города доставка бесплатная до пункта
            выдачи СДЭК при заказе от 10000 рублей.
          </h3>
        </div>

        <hr />

        {cart
          .filter((item) => item.is_active)
          .map((item, index) => (
            <div
              key={item.id}
              className="grid grid-cols-[auto,auto,130px] gap-2 justify-between items-center py-2">
              <p className="text-start">{item.name}</p>
              <p>
                Цена: {item.price} р. / {item.unit}
              </p>

              <div className="flex items-center text-black bg-white rounded-full py-2 border border-gray-300 w-full justify-between">
                <button
                  onClick={() => updateQuantity(item.id, index === 0 ? -0.5 : -1)}
                  className="px-2 focus:outline-none">
                  -
                </button>
                <span className="px-4">{item.quantity}</span>
                <button
                  onClick={() => updateQuantity(item.id, index === 0 ? 0.5 : 1)}
                  className="px-2 focus:outline-none">
                  +
                </button>
              </div>
            </div>
          ))}

        <hr />
        <div className="flex justify-end">
          <p className="text-lg py-4">ИТОГО: {calculateTotalPrice().toLocaleString()} р.</p>
        </div>

        <div className="py-2 flex flex-col gap-2">
          <form className="space-y-2 text-black">
            <input
              type="text"
              name="name"
              placeholder="Имя"
              value={customerData.name}
              onChange={handleInputChange}
              className="w-full p-3 py-2 border border-gray-300 rounded"
            />
            <InputMask
              mask="+7 (999) 999-99-99"
              placeholder="Контактный номер"
              name="phone"
              value={customerData.phone}
              onChange={handleInputChange}
              className="w-full p-3 py-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="city"
              placeholder="Город"
              value={customerData.city}
              onChange={handleInputChange}
              className="w-full p-3 py-2 border border-gray-300 rounded"
            />
          </form>

          <button
            onClick={handleSubmitOrder}
            className="mt-6 px-4 py-3 font-bold bg-[#EF6F0E] text-white rounded-full w-full">
            Оформить заказ на {calculateTotalPrice().toLocaleString()} р.
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderModal;
