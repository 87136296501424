import React, { useEffect, useState } from 'react';
import { getOrders, deleteOrder } from '../../api';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 5;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getOrders();
        setOrders(Array.isArray(data) ? data : []); // Убедитесь, что data — массив
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setOrders([]); // Устанавливаем пустой массив при ошибке
      }
    };

    fetchOrders();
  }, []);

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrder(id);
      setOrders(orders.filter((order) => order.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении заказа:', error);
    }
  };

  // Функция для перехода на указанную страницу
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Вычисляем текущие заказы для отображения
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Вычисляем общее количество страниц
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Список заказов</h2>
      <ul className="space-y-6">
        {currentOrders.map((order) => (
          <li key={order.id} className="bg-white shadow rounded-lg p-6">
            <div className="mb-4">
              <p className="text-lg font-bold text-gray-800">Имя: {order.name}</p>
              <p>Город: {order.city}</p>
              <p>Телефон: {order.phone_number}</p>
              <p>Дата заказа: {new Date(order.order_date).toLocaleString()}</p>
            </div>

            <div className="mt-2">
              <h3 className="font-semibold text-gray-800">Товары в заказе:</h3>
              <ul className="ml-4 list-disc text-gray-600">
                {order.items.map((item) => (
                  <li key={item.product_id}>
                    {item.product_name} — {item.quantity} шт. по {item.price} р.
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={() => handleDeleteOrder(order.id)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200">
              Удалить
            </button>
          </li>
        ))}
      </ul>

      {/* Пагинация */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`px-3 py-1 mx-1 rounded ${
              currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'
            }`}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Orders;
