import React, { useEffect } from 'react';
import { getProducts } from '../api';

function Block5({ openModal, products, setProducts }) {
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        setProducts(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Ошибка при загрузке продуктов:', error);
        setProducts([]);
      }
    };

    fetchProducts();
  }, [setProducts]);

  return (
    <div className="h-full w-full flex flex-col items-center justify-around bg-black px-6 lg:px-16 py-8 lg:pt-32 text-white">
      <div className="flex flex-col items-center justify-center relative z-10">
        <h2 className="text-3xl lg:text-5xl text-center lg:font-semibold font-bold">Товары</h2>
        <div className="flex justify-center pt-4 relative z-10 text-center">
          <h3 className="text-xl">
            Наши продукты не продаются в магазинах, они доставляются только напрямую с пасеки по
            Вашим заказам: по телефону или через сайт. Бесплатная доставка до пункта СДЭК в Вашем
            городе при заказе от 10 000 руб. Иные способы доставки возможны и оговариваются
            индивидуально.
          </h3>
        </div>
      </div>
      <div className="flex justify-center w-full py-8 lg:py-0">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-14 w-full lg:w-auto mt-2">
          {products.map((product, index) => (
            <div
              key={product.id}
              className="flex flex-col gap-3 justify-center items-center text-xl text-white">
              <img
                src={`/images/image_${index + 1}.png`}
                className="h-64"
                alt={`Продукт ${product.name}`}
              />
              <p className="text-center">{product.name}</p>
              <p className="text-center font-semibold">
                {product.price} р./{product.unit}
              </p>
              {product.is_active ? (
                <button
                  onClick={() => openModal(product)}
                  className="px-14 py-3 bg-[#D95F03] rounded-full w-full lg:w-auto hover:bg-[#b3743d] transition-colors duration-300 text-xl">
                  Заказать
                </button>
              ) : (
                <button
                  disabled
                  className="px-14 py-3 bg-gray-500 rounded-full w-full lg:w-auto text-xl cursor-not-allowed">
                  Нет в наличии
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Block5;
