import React from 'react';

function Footer() {
  return (
    <footer className="h-full w-full flex flex-col items-center justify-center bg-black px-8 py-16 md:px-32 md:pt-32 text-white">
      <div className="flex justify-center relative z-10">
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-center lg:font-semibold font-bold">
          Контакты
        </h2>
      </div>
      <div className="text-xl lg:text-2xl flex flex-col justify-center items-center text-center mt-8 gap-2">
        <p>пасека "Полярное сияние" им. В.Ф.Марарицы.</p>
        <p className="text-center px-4 md:px-0 mb-8">
          184703, РФ, Мурманская область, Терский район, посёлок Умба, ул. Луговая, д.9
        </p>
        <p>Новиков Евгений Андреевич</p>
        <p>
          <a href="tel:+79217584440" className="text-[#DB8F4D]">
            +7 (921) 758-4440
          </a>
        </p>
        <p>
          <a href="mailto:evgeniy@polarhoney.ru" className="text-[#DB8F4D]">
            evgeniy@polarhoney.ru
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
