import React, { useEffect, useState } from 'react';
import { getQuantity } from '../api';

function Block3({ openModal }) {
  const [quantity, setQuantity] = useState({ total_collected: 0, remaining: 0 });

  useEffect(() => {
    const fetchQuantity = async () => {
      try {
        const data = await getQuantity();
        setQuantity(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных количества:', error);
      }
    };

    fetchQuantity();
  }, []);

  return (
    <div
      className="relative h-full w-full bg-black px-6 py-8 lg:px-16 lg:py-32 bg-cover text-white flex flex-col gap-6 lg:gap-10 justify-around items-center"
      style={{ backgroundImage: 'url("/images/block2.jpg")' }}>
      <div className="flex justify-center relative z-10">
        <h2 className="text-3xl lg:text-5xl text-center lg:font-semibold font-bold">Урожай 2024</h2>
      </div>
      <div className="flex flex-col justify-center w-full lg:w-auto items-center gap-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-8 text-2xl lg:text-2xl w-full text-[#FAF1EA]">
          <div
            className="w-full px-10 lg:px-20 rounded-3xl py-6 flex flex-col justify-between"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
            <div>Всего собрали:</div>
            <div className="text-2xl lg:text-3xl bg-white rounded-xl px-2 py-2 lg:py-5 text-center mt-3 lg:mt-8 text-black">
              {quantity.total_collected} кг
            </div>
          </div>
          <div
            className="w-full px-10 lg:px-20 rounded-3xl py-6 flex flex-col justify-between"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
            <div>
              <p>Осталось:</p>
            </div>
            <div className="text-2xl lg:text-3xl bg-white rounded-xl px-2 py-2 lg:py-5 text-center mt-3 lg:mt-8 text-black">
              {quantity.remaining} кг
            </div>
          </div>
        </div>
        <button
          onClick={() =>
            openModal(
              quantity.remaining > 0
                ? { name: 'Мёд Заполярья', price: 4000, unit: 'кг' }
                : { name: 'Предзаказ на следующий урожай', price: 4000, unit: 'кг' },
            )
          }
          className="w-full py-4 lg:py-6 bg-[#D95F03] text-white rounded-full uppercase text-xl lg:text-3xl font-bold hover:bg-[#d65e0c] transition-colors duration-300">
          {quantity.remaining > 0 ? 'Заказать' : 'Предзаказ на следующий урожай'}
        </button>
      </div>
    </div>
  );
}

export default Block3;
